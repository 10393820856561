@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Lexend:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Lexend:wght@100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext'); */


:root {
  --font-inter: 'Inter', sans-serif;
  --font-lexend: 'Lexend', sans-serif;
}

body {
  font-family: var(--font-inter);
}

.font-lexend {
  font-family: var(--font-lexend);
}

.input-invalid {
  border-color: red;
  color: red; 
}

.input-invalid:focus {
  border-color: red;
  box-shadow: red;
  outline: none;
}